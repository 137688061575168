.movie-container{
    margin-right: 1rem;
    width: 12.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.movieArt{
    width: 12.25rem;
    height: 17.625rem;
    border-radius: 10px;
    margin-bottom: .5rem;
}
.title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: center;
    width: 10rem;
}
.rating{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}
.ratingContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.ratingContainer p{
    margin-left: 1rem;
    margin-top: .3rem;
}

@media only screen and (min-width: 600px) {  }
  