.header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
    height: 5rem;
    font-family: 'Nunito', sans-serif;
}
#logo{
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 50px;
    /* or 308% */
    text-align: center;
    letter-spacing: 0.04em;
    color: #fff;
}
a{
    text-decoration: none;
}
#desc{
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 10px;
    /* or 111% */
    text-align: center;
    letter-spacing: 0.04em;
    width: 70%;
    color: #fff;
}
@media only screen and (min-width: 600px) {
    .header{
      height: 7rem;
    }
  }
  