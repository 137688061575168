.top-rated-page{
    min-height: calc(100vh - 5rem);
}
.top-rated-movies{
    display: grid;
    grid-gap: 0.5rem;
    min-height: 60vh;
    grid-template-columns: repeat(auto-fill, minmax(12.25rem, 0.75fr));
    margin: 0 1rem;
}

.top-rated-h1{
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
}
.top-header-container{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
}
#filter-icon{
    color: #ec1f41;
    font-size: 1.25rem;
    cursor: pointer;
}
.filter-container{
    margin: 1rem;
}
.filter-options{
    display: flex;
    align-items: baseline;
    margin-right: 1rem;
}
.filter-options select{
    border: 1px solid #ec1f41;
    border-radius: 10px;
    background-color: inherit;
    color: #fff;
    margin-left: .5rem;
    margin-bottom: 1rem;
    padding: .75rem 1rem;
}
.filter-options select:focus{
    outline: none;
}
.filter-options-container{
    display: flex;
}
.pagination-container{
    margin: 0 1rem;
}
.pagination{
    display: flex;
    justify-content: space-between;
}
.back-btns, .forward-btns{
    display: flex;
}
.back-btns >.prev-btn, .forward-btns>.next-btn{
    margin-right: 1rem;
}
.prev-btn, .next-btn{
    padding: .5rem .75rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 1rem;
}
.prev-btn:hover, .next-btn:hover{
    background-color: #dddddd;
}
.prev-btn:disabled,  .next-btn:disabled{
    background-color: #646464;
}
.prev-btn:hover:disabled,  .next-btn:hover:disabled{
    background-color: #646464;
}
.top-spinner{
    display: flex;
    justify-content: center;
    min-height: 60vh;
}
@media only screen and (min-width: 600px) {
    .top-header-container{
        margin: 1rem 5rem;
    }
    .top-rated-movies{
        margin: 0 5rem;
    }
    .filter-container{
        margin: 1rem 5rem;
    }
    .pagination-container{
        margin: 1rem 5rem;
    }
}
  