*{
  margin: 0;
  border: 0;
  padding: 0;
}
html{
  position: relative;
  min-height: 100%;
}
body{
  width: 100vw;
  background-color: #1A1A1A;
  font-family: 'Tajawal', sans-serif;
  color: #ffffff;
}
button{
  cursor: pointer;
}
.spinner-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}


@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}
