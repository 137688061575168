.login-container{
  display: flex;
  align-items: baseline;
}
.login-container>input{
  margin-right: 10px;
}
.alt-option-container{
  margin-top: 1rem;
}
.alt-option{
  color: rgb(65, 65, 255);
  text-decoration: underline;
}
