#footer{
    background-color: #000;
    width: 100%;
    margin-top: 1.5rem;
}
#footer p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */
    letter-spacing: 0.05em;
    /* white */
    color: #FFFFFF;
    padding: 1rem;
}