.popular-page{
    min-height: 80vh;
}

.popular-movies{
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(12.25rem, 0.75fr));
}
.popular-h1{
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
}

@media only screen and (min-width: 600px) {
    .popular-movies{
        margin: 0 5rem;
    }

  }
  