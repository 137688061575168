.admin-header-container{
  background-color: #080E12;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
}
.admin-header{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

@media only screen and (min-width: 600px) {
  .admin-header{
    margin: 0 5rem;
  }  
}