.add-page{
    margin: 1rem;
    min-height: calc(100vh - 5rem);
}

.form-group input{
    height: 24px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    background: #191919;
    color: #ffffff;
    padding: 10px;
}
.form-group select{
    height: 44px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    background: #191919;
    color: #ffffff;
    padding: 10px;
}
input:focus, textarea:focus, select:focus{
    outline: none;
}
textarea{
    resize: none;
}

.form-group{
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}
.add-form{
    width: 90%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.title-group{
    display: flex;
}
.genre-location-group{
    display: flex;
}
.title-container{
    flex: 65%;
}
.genre-container{
    flex: 65%;
}
.year-container{
    flex: 35%;
    padding-left: 10px;
}
.location-container{
    flex: 35%;
    padding-left: 10px;
}
.submit-btn{
    background: #EC1F41;
    padding: .75rem 1rem;
    border-radius: 10px;  
    margin-top: 1rem;
    color: #fff;
    font-size: 1rem;
    max-height: 2.75rem;
    cursor: pointer;
}
.submit-btn:disabled{
    background: #646464;
}
button:focus{
    outline: none;
}

.image-file{
    display: flex;
    flex-direction: column;
}
.error{
    color: #EC1F41;
}
.success{
    color: #52a911
}
.star-rating-container > p{
    margin-top: 1rem;
}
@media only screen and (min-width: 600px) {
    .add-page{
        margin: 1rem 5rem;
    }  
}
