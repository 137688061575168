.movie-page{
  min-height: calc(100vh - 10rem);
  margin: 1rem;
}
.review-avatar{
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.reviews{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.reviews>img{
  margin-right: 1rem;
}
.reviews-container{
  margin-top: 1.5rem;
}
.review-form{
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.signup-btn, .login-btn{
  padding: 1rem;
  border-radius: 10px;
  width: 40%;
  font-size: 1rem;
  cursor: pointer;
}
.login-btn{
  margin-left: 10%;
}
@media only screen and (min-width: 600px) {
  .movie-page{
      margin: 1rem 5rem;
  }  
}
