.display-movies{
  margin-bottom: 2rem;
  max-width: 40rem;
}
.edit-link{
  display: inline-block;
}
.btn-container{
  display: flex;

}
.edit-btn{
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  border-radius: 5px;
  background: #705CFB;
  color: #fff;
}
#edit-icon{
  margin-right: 5px;
}
.delete-btn{
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  border-radius: 5px;
  background: red;
  color: #fff;
  margin-left: 1.5rem;
}
#delete-icon{
  margin-right: 5px;
}