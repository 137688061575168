.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
  opacity: 1;
  animation: fade .5s linear forwards;
}
#twitter-logo, #facebook-logo{
  color: #fff;
}
.twitter-container, .facebook-container{
  display: flex;
  align-items: center;
  width: 6rem;
  padding: .5rem;
  border-radius: 5px;
  margin: 1rem 1.5rem;
}
.twitter-container{
  background-color: rgb(29, 161, 242)
}
.facebook-container{
  background-color: #305c99;
}
.twitter, .facebook{
  border-left: 1px solid #e2e2e2;
  margin-left: 5px;
  padding-left: 5px;
}
.twitter>p, .facebook>p{
  text-align: center;
  line-height: 1.5em;
}


.modal-main {
  position:fixed;
  background: #191919;
  width: 90%;
  max-width: 30rem;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: #fff;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.header-container{
  background: #000;
  padding: 1.5rem 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
}
#close-btn{
  font-size: 1.5rem;
  flex: 10%;
  cursor: pointer;
}
.header-container p{
  flex: 100%;
}
.h3{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  /* identical to box height */
  text-align: center;
}
.movie-info{
  padding: 1.5rem 1rem;
  display: flex;
}
.movieArt{
  /* width: 12.25rem;
  height: 17.625rem; */
  width: 10rem;
  height: 14.25rem;
  border-radius: 10px;
}
.title-container{
  margin-left: .75rem;
  width: 100%;
}
.info-container{
  flex: 70%;
}
.info-container>.share-p{
  margin-left: 1rem;
}
.synopsis-container{
  margin: .75rem;
}
.movie-title{
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
.movie-genre{
  font-size: 1rem;
  line-height: 1rem;
  opacity: 0.6;
  margin-top: .5rem;
}

.synopsis-header{
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
}
.synopsis-content{
  font-size: .85rem;
  line-height: .85rem;
  opacity: 0.6;
  margin-top: .5rem;
}
hr{
  border: 0;
  display:block;
  width: 96%;               
  background-color:#cfcfcf;
  height: 1px;
  margin-top: .5rem;
}
.big-hr{
  width: 100%;
}
.rating-container{
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rate-btn{
  background: #EC1F41;
  padding: 1rem 1.5rem;
  border-radius: 10px;
}
#star-icon{
  font-size: 2rem;
}
#share-star-icon{
  font-size: 1.5rem;
}